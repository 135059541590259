import Vue from "vue";
import VueRouter from "vue-router";
import { MessageBox } from 'element-ui'
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () =>
            import("../views/home/index")
    },

    {
        path: '/advanceBatch',
        name: 'advanceBatch',
        component: () => import('../views/advanceBatch/index.vue')
    },

    {
        path: '/search',
        name: 'search',
        component: () => import('../views/home/search.vue')
    },
    {
        path: "/Login",
        name: "Login",
        component: () =>
            import("../views/login/index")
    },
    {
        path: "/Register",
        name: "Register",
        component: () =>
            import("../views/Register")
    },
    {
        path: "/help",
        name: "help",
        component: () =>
            import("../views/help")
    },
    //个人信息
    {
        path: '/information',
        name: 'information',
        component: () => import('../views/user/Information.vue')
    },
    //个人中心
    {
        path: '/User',
        component: () => import('../views/user'),
        children: [
            {
                path: 'info',
                name: 'info',
                meta: '个人资料',
                component: () => import('../views/user/Info.vue')
            },
            {
                path: 'myVolunteer',
                name: 'myVolunteer',
                meta: '我的志愿表',
                component: () => import('../views/user/myVolunteer.vue')
            },
            {
                path: 'testReport',
                name: 'testReport',
                meta: '我的测评',
                component: () => import('../views/user/testReport.vue')
            },
            {
                path: 'couponInfo',
                name: 'couponInfo',
                meta: '优惠卷',
                component: () => import('../views/user/components/coupon.vue')
            },

            {
                path: 'attCollege',
                name: 'attCollege',
                meta: '关注院校',
                component: () => import('../views/user/attCollege.vue')
            },
            {
                path: 'attSpecialty',
                name: 'attSpecialty',
                meta: '关注专业',
                component: () => import('../views/user/attSpecialty.vue')
            },
            {
                path: 'down-zhuanke',
                name: 'down-zhuanke',
                meta: '关注专业',
                component: () => import('../views/user/down-zhuanke.vue')
            },

            {
                path: 'myCard',
                name: 'myCard',
                meta: '我的会员卡',
                component: () => import('../views/user/MyCard.vue')
            },
            {
                path: 'review',
                name: 'review',
                component: () => import('../views/review/index.vue')
            },
            {
                path: 'ChangePwd',
                name: 'ChangePwd',
                meta: '修改密码',
                component: () => import('../views/user/ChangePwd.vue')
            },

        ]
    },
    {
        path: "/NewsList",
        name: "NewsList",
        meta: {
            keepAlive: true // 需要缓存
        },
        component: () =>
            import("../views/news/NewsList")
    },
    {
        path: "/Detail",
        name: "Detail",
        component: () =>
            import("../views/news/Detail")
    },
    {
        path: "/lookSpecialty",
        name: "lookSpecialty",
        meta: {
            keepAlive: true // 需要缓存
        },
        component: () =>
            import("../views/specialty/look-specialty")
    },
    {
        path: '/specialtyInfo',
        name: 'specialtyInfo',
        component: () => import('../views/specialty/specialtyInfo.vue')
    },
    {
        path: "/lookCollege",
        name: "lookCollege",
        component: () => import("../views/college/look-college")
    },
    {
        path: "/College",
        component: () => import("../views/college/College"),
        children: [
            {
                path: "",
                name: "CollegeInfo",
                component: () => import("../views/college/Info"),
            },
            {
                path: "schoolNews",
                name: "schoolNews",
                component: () => import("../views/college/SchoolNews"),
            },
            {
                path: '/solution',
                name: 'solution',
                meta: '招生简章详情',
                component: () => import('../views/college/solution.vue')
            },
            {
                path: "getPlan",
                name: "getPlan",
                component: () => import("../views/college/get-plan"),
            },
            {
                path: "SpecialtyScore",
                name: "SpecialtyScore",
                component: () => import("../views/college/SpecialtyScore"),
            },
            {
                path: "collegeScoreLine",
                name: "collegeScoreLine",
                component: () => import("../views/college/collegeScoreLine"),
            },
            {
                path: "artScore",
                name: "artScore",
                component: () => import("../views/college/artScore"),
            },
            {
                path: "otherBatch",
                name: "otherBatch",
                component: () => import("../views/college/otherBatch"),
            },
            {
                path: "otherBatchScore",
                name: "otherBatchScore",
                component: () => import("../views/college/otherBatchScore"),
            },

        ]
    },

    {
        path: '/newChooseSubject',
        component: () => import("../views/new-choose-subject/index"),
        children: [
            {
                path: "",
                name: 'newChooseSubject',
                component: () => import("../views/new-choose-subject/overview"),
            },

            {
                path: "newSpecialtySearch",
                name: 'newSpecialtySearch',
                component: () => import("../views/new-choose-subject/search"),
            },

            {
                path: "chooseSpecialty",
                component: () => import("../views/new-choose-subject/choose-specialty/index"),
                children: [
                    {
                        path: "",
                        name: 'chooseSpecialty',
                        component: () => import("../views/new-choose-subject/choose-specialty/scheme"),
                    },
                    {
                        path: "analyse",
                        name: 'analyse',
                        component: () => import("../views/new-choose-subject/choose-specialty/analyse"),
                    },
                ]
            },
            {
                path: "newSubjectUser",
                name: 'newSubjectUser',
                component: () => import("../views/new-choose-subject/user/index"),

            },
        ],

    },
    {
        path: '/provinceLine',
        name: 'provinceLine',
        meta: {
            // keepAlive: true // 需要缓存
        },
        component: () => import('../views/score/provinceLine.vue')
    },
    {
        path: '/ranking',
        name: 'ranking',
        component: () => import('../views/score/ranking.vue')
    },
    {
        path: "/Recommend",
        name: "Recommend",
        component: () => import("../views/recommend/Index")
    },
    {
        path: "/volunteer",
        component: () => import("../views/volunteer/Index"),
        children: [
            {
                path: "CollegeFirst",
                name: "CollegeFirst",
                component: () => import("../views/volunteer/CollegeFirst"),
            },
            {
                path: "ReportTable",
                name: "ReportTable",
                component: () => import("../views/volunteer/ReportTable"),
            },
            {
                path: "",
                name: "OneKey",
                component: () => import("../views/volunteer/OneKey"),
            },
            {
                path: "MajorFirst",
                name: "MajorFirst",
                component: () => import("../views/volunteer/MajorFirst"),
            },
        ]
    },

    {
        path: "/volunteer_art",
        component: () => import("../views/volunteer_art/index"),
        children: [
            {
                path: "",
                name: "art_college_list",
                component: () => import("../views/volunteer_art/college_list"),
            },
            {
                path: "art_calculate",
                name: "art_calculate",
                component: () => import("../views/volunteer_art/calculate"),
            },
            {
                path: "art_volunteer",
                name: "art_volunteer",
                component: () => import("../views/volunteer_art/volunteer"),
            },
            {
                path: "report_table_art",
                name: "report_table_art",
                component: () => import("../views/volunteer_art/report_table_art"),
            },
        ]
    },

    {
        path: '/eq',
        name: 'eq',
        component: () => import('../views/assess/eq.vue')
    },
    {
        path: "/holland",
        name: "holland",
        component: () => import("../views/assess/holland")
    },
    {
        path: '/mbti',
        name: 'mbti',
        component: () => import('../views/assess/mbti.vue')
    },

    {
        path: '/mbti_two',
        name: 'mbti_two',
        component: () => import('../views/assess/mbtiTwo.vue')
    },

    {
        path: '/scl',
        name: 'scl',
        component: () => import('../views/assess/scl.vue')
    },


    {
        path: '/interest',
        name: 'interest',
        component: () => import('../views/assess/interest.vue')
    },
    {
        path: "/eqResult",
        name: "eqResult",
        component: () => import("../views/assess/result/eq")
    },

    {
        path: "/sclResult",
        name: "sclResult",
        component: () =>
            import("../views/assess/result/scl")
    },

    {
        path: "/hollandResult",
        name: "hollandResult",
        component: () =>
            import("../views/assess/result/holland")
    },



    {
        path: '/interestResult',
        name: 'interestResult',
        component: () => import('../views/assess/interestResult.vue')
    },

    {
        path: '/artCollege',
        name: 'artCollege',
        component: () => import('../views/art/college/index.vue')
    },
    //服务商品
    {
        path: '/server',
        name: 'server',
        meta: {
            // keepAlive: true // 需要缓存
        },
        component: () => import('../views/server/index.vue')
    },
    {
        path: '/coupon',
        name: 'coupon',
        component: () => import('../views/coupon.vue')
    },
    {
        path: '/goodsInfo',
        name: 'goodsInfo',
        component: () => import('../views/server/goodsInfo.vue')
    },
    {
        path: '/vip',
        name: 'vip',
        component: () => import('../views/server/vip.vue')
    },

    //数据看板
    {
        path: '/lookData',
        name: 'lookData',
        component: () => import('../views/look-data/index.vue')
    },
    {
        path: '/PdfVolunteer',
        name: 'PdfVolunteer',
        component: () => import('../views/volunteer/PdfVolunteer.vue')
    },
];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes
});
router.beforeEach((to, from, next) => {

    console.log(to.name)

    // to: Route: 即将要进入的目标 路由对象
    // from: Route: 当前导航正要离开的路由
    // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
    const token = localStorage.getItem('TOKEN') // 是否登录

    //白名单
    const whiteRoute = ['Home', 'search', 'help', 'Login', 'information', 'Register', 'lookSpecialty', 'NewsList', 'Detail', 'lookCollege', 'Recommend', 'server', 'vip', 'art_detail', 'art_solution', 'PdfVolunteer']

    // 注册用户0
    const loginRoute = ['info', 'specialtyInfo', 'CollegeInfo', 'schoolNews', 'solution', 'provinceLine', 'ranking', 'lookData', 'attCollege', 'couponInfo', 'myVolunteer', 'testReport', 'myCard', 'review', 'ChangePwd', 'goodsInfo', 'coupon', 'chooseSpecialty']

    //vip 路由 1
    const VipList = ['info', 'specialtyInfo', 'CollegeInfo', 'schoolNews', 'solution', 'provinceLine', 'ranking', 'lookData', 'attCollege', 'couponInfo', 'myVolunteer', 'testReport', 'myCard', 'down-zhuanke', 'review', 'ChangePwd', 'OneKey', 'CollegeFirst', 'MajorFirst', 'ReportTable', 'eq', 'holland', 'mbti', 'mbti_two', 'scl', 'escort', 'interest', 'goodsInfo', 'collegeScoreLine', 'SpecialtyScore', 'getPlan', 'otherBatch', 'otherBatchScore', 'newChooseSubject', 'newSpecialtySearch','hollandResult', 'chooseSpecialty', 'newSubjectUser', 'analyse', 'eqResult', 'sclResult', 'interestResult', 'advanceBatch', 'strong_base', 'special_plan', 'coupon'
    ]

    //美术类 2
    const artVipList = ['info', 'specialtyInfo', 'CollegeInfo', 'schoolNews', 'solution', 'provinceLine', 'ranking', 'lookData', 'attCollege', 'couponInfo', 'myVolunteer', 'testReport', 'myCard', 'down-zhuanke', 'review', 'ChangePwd', 'OneKey', 'CollegeFirst', 'MajorFirst', 'ReportTable', 'eq', 'holland', 'mbti', 'mbti_two', 'scl', 'escort', 'interest', 'goodsInfo', 'collegeScoreLine', 'SpecialtyScore', 'getPlan', 'otherBatch', 'otherBatchScore', 'newChooseSubject', 'newSpecialtySearch','hollandResult', 'chooseSpecialty', 'newSubjectUser', 'analyse', 'eqResult', 'sclResult', 'interestResult', 'advanceBatch', 'strong_base', 'special_plan', 'art_college_list', 'art_calculate', 'art_volunteer', 'report_table_art', 'artCollege', 'artScore', 'coupon'
    ]


    //回到首页
    if (to.name == null) {
        router.push('/')
    }
    //如果是白名单。直接访问不用注册直接访问
    if (whiteRoute.indexOf(to.name) >= 0) {
        next()
    } else {
        //如果不是白名单 判断是否登录 登录可看
        // 判断是否登录

        if (token) {

            const is_wenli = JSON.parse(localStorage.getItem('UserInfo')).is_wenli

            // 如果没有文理科。直接跳转到修改个人信息
            if (!is_wenli && to.name != 'information') {
                next(`/information?redirect=${to.path}`)
                return
            }

            const VIP = JSON.parse(localStorage.getItem('UserInfo')).is_vip  // 判断VIP类型

            switch (VIP) {
                case 0:
                    if (loginRoute.indexOf(to.name) > -1) {
                        next()
                    } else {
                        MessageBox.confirm('该功能仅VIP用户可用！', '提示', {
                            center: true,
                            confirmButtonText: '开通vip',
                            callback: action => {
                                if (action === 'confirm') {
                                    router.push('/vip')
                                }
                            }
                        })
                        return
                    }
                    break;
                case 1:
                    if (VipList.indexOf(to.name) > -1) {
                        next()
                    } else {
                        MessageBox.confirm('该功能仅VIP美术类用户可用！', '提示', {
                            center: true,
                            confirmButtonText: '开通vip',
                            callback: action => {
                                if (action === 'confirm') {
                                    router.push('/vip')
                                }
                            }
                        })
                        return
                    }
                    break;
                case 2:
                    if (artVipList.indexOf(to.name) > -1) {
                        next()
                    } else {
                        MessageBox.confirm('该功能仅VIP美术类用户可用！', '提示', {
                            center: true,
                            confirmButtonText: '开通vip',
                            callback: action => {
                                if (action === 'confirm') {
                                    router.push('/vip')
                                }
                            }
                        })
                        return
                    }
                    break;
                case 3:
                    if (VipList.indexOf(to.name) > -1) {
                        next()
                    } else {
                        MessageBox.confirm('该功能仅VIP美术类用户可用！', '提示', {
                            center: true,
                            confirmButtonText: '开通vip',
                            callback: action => {
                                if (action === 'confirm') {
                                    router.push('/vip')
                                }
                            }
                        })
                        return
                    }
                    break;
            }

        } else {
            //去登录页面
            MessageBox.confirm('该功能仅注册用户可用！', '提示', {
                center: true,
                confirmButtonText: '注册',
                callback: action => {
                    if (action === 'confirm') {
                        router.push('/Login')
                    }
                }
            })
        }
    }
})


export default router;
